html, body {
    padding: 0;
    margin: 0;
    min-height: 100vh;
    font-family: Arial, sans-serif;
    font-size: 16px;
    background: #2288bb;
}

::placeholder {
    color: rgba(255, 255, 255, 0.8);
    opacity: 1;
}
:-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}
::-ms-input-placeholder {
    color: rgba(255, 255, 255, 0.8);
}

@-webkit-keyframes autofill {
    to {
        color: rgba(255, 255, 255, 0.8);
        background: transparent;
    }
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;
}

.login-box {
    background: rgba(0, 0, 0, 0.7);
    width: 240px;
    padding: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -160px 0 0 -160px;
}

.login-box label {
    font-size: 11px;
    font-weight: bold;
    color: #fff;
    letter-spacing: 1px;
}

.login-box input {
    border-width: 0 0 1px 0;
    border-color: rgba(255, 255, 255, 0.8);
    border-style: solid;
    background: transparent;
    color: rgba(255, 255, 255, 0.8);
    font-family: Arial, sans-serif;
    font-size: 16px;
    padding: 10px 0;
    margin-bottom: 30px;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    letter-spacing: 1px;
}

.login-box input:focus {
    border-color: #3399cc;
}

.login-box button {
    border: none;
    background: #3399cc;
    color: #fff;
    font-family: Arial, sans-serif;
    font-size: 14px;
    padding: 10px 0;
    margin-bottom: 20px;
    box-sizing: border-box;
    width: 100%;
    outline: none;
    cursor: pointer;
    letter-spacing: 1px;
}

.login-box button:hover {
    background: #2288bb;
}

.login-box button:focus {
    background: #44aabb;
}

.login-box p {
    margin: 0;
    padding: 0;
    text-align: center;
}

.login-box a {
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;
    text-decoration: none;
    letter-spacing: 1px;
}

.login-box a:hover {
    color: #fff;
}

.title {
    padding-bottom: 5px;
    display: block;
    font-size: 11px;
    text-transform: uppercase;
}

.loading {
    font-size: 10px;
    text-transform: uppercase;
    color: red;
}

.empty {
    font-size: 12px;
    font-weight: bold;
    color: #2288bb;
}

.info {
    padding: 15px;
    background: #fff;
    min-height: 100vh;
}

.item-list {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    padding-bottom: 7px;
}

.item {
    padding: 5px 7px;
    background: #2288bb;
    border-radius: 10px;
    font-size: 14px;
    font-weight: bold;
    color: #fff;
    white-space: nowrap;
}

.item span {
    font-size: 10px;
}

.small-info {
    font-size: 12px;
    font-weight: bold;
    padding-bottom: 20px;
}

.flex {
    padding-bottom: 30px;
}

.info-modal {
    width: 300px;
    position: absolute;
    top: 20vh;
    left: 50%;
    margin-left: -150px;
    background: #fff;
    padding: 10px;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 14px;
}

table {
    border-collapse: collapse;
}

td {
    padding: 5px;
    font-size: 12px;
}

.data-value {
    overflow-wrap: anywhere;
    word-break: break-all;
}

.close-modal {
    text-align: right;
    font-size: 24px;
    font-weight: bold;
    margin-top: -5px;
}

@media screen and (max-width: 500px) {
    .flex {
        display: flex;
        gap: 20px;
    }
    .flex > div {
        width: 50%;
    }
}